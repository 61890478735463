import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { MetaDescription, Title } from "../components/metadata";
import confusedTravolta from "../images/confused-travolta.gif";

const title = "Page not found";

const CenteredParagraph = styled.p`
  text-align: center;
`;

const Metadata = () => (
  <>
    <Title>{title}</Title>
    <MetaDescription>Page not found.</MetaDescription>
  </>
);

const NotFound = () => (
  <Layout title={title}>
    <Metadata />
    <CenteredParagraph>
      <img alt="Not found" title="Confused Travolta" src={confusedTravolta} />
    </CenteredParagraph>
  </Layout>
);

export default NotFound;
